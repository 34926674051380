import {NavLink, useNavigate} from "react-router-dom";
import './index.css';
import 'animate.css';
import Search from "antd/es/input/Search";
import {Button, Dropdown, Space} from "antd";
import {DownOutlined, GithubOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {getToken} from "../../util/auth";
import {customMenuArr} from "../../config/router";
import {doInPc, doInPhone} from "../../util/device";

const renderMenuPage = (item, index) => {
  let hasChild = false;
  if (typeof item.children !== 'undefined') {
    let children = item.children;
    if (!getToken()) {
      children = item.children.filter(customMenu => customMenu.auth !== 'true');
    }
    hasChild = children.length > 1;

    if (children.length === 1) {
      item = children[0];
    }
  }

  if (hasChild) {
    const subMenu = [];
    item.children.forEach(i => {
      subMenu.push({
        label: (
          <NavLink
            key={i.path}
            to={i.path}
            className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : ""}
          >
            {i.label}
          </NavLink>
        ),
        key: i.path,
      })
    });

    return (
      <div key={index}>
        <Dropdown bordered={false} menu={{items: subMenu}} trigger={['hover']}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {item.label}
              <DownOutlined/>
            </Space>
          </a>
        </Dropdown>
      </div>
    );
  } else {
    return (
      <NavLink
        key={index}
        to={item.path}
        className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : ""}
      >
        {item.label}
      </NavLink>
    );
  }
}

/**
 * 菜单
 */
const CustomMenu = () => {
  const navigate = useNavigate();

  // 菜单
  const [menuArr, setMenuArr] = useState([]);

  // 监听登录
  const listenLoginBtn = () => {
    navigate("/login");
  }

  const handleMenuClick = (e) => {
    const label = e?.domEvent?.target?.innerText;
    if (!label) {
      return;
    }

    const arr = [...menuArr];
    while (arr && arr.length !== 0) {
      const item = arr.pop();
      if (item.label === label) {
        navigate(item.path);
        return;
      }
      if (item?.children && item.children.length !== 0) {
        arr.push(...item.children);
      }
    }
  };

  useEffect(() => {
    const arr = customMenuArr;
    if (getToken()) {
      setMenuArr(arr);
    } else {
      const new_arr = [];
      for (let ele of arr) {
        if (ele.auth === 'true') {
          continue;
        }
        if (ele?.children && ele.children.length !== 0) {
          ele.children = ele.children.filter(child => child.auth !== 'true');
          if (ele.children.length === 0) {
            continue;
          }
        }

        new_arr.push(ele);
      }
      setMenuArr(new_arr);
    }
  }, []);

  return (
    <div className={'custom-menu-main'}>
      <div className={'logo'}>
        <NavLink to={'/'}>
          {
            doInPc(
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={'loader'}></div>
                <span className={'animate__bounce'}>Autumn Of CoolerWu</span>
              </div>
            )
          }
          {
            doInPhone(<span className={'animate__bounce'}>Autumn Of CoolerWu</span>)
          }
        </NavLink>
      </div>
      <div className={'tag'}>
        {
          document.inner.isPC ?
            menuArr.map((item, index) => renderMenuPage(item, index)) : (
              <>
                <Dropdown menu={{items: menuArr, onClick: handleMenuClick}} trigger={['hover']}>
                  <Button type={'text'}>
                    <Space>
                      菜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;单
                      <DownOutlined/>
                    </Space>
                  </Button>
                </Dropdown>
              </>
            )
        }
      </div>
      {
        document.inner.isPC && (
          <div className={'search'}>
            <Button
              type={'text'} shape={'circle'} icon={<GithubOutlined/>}
              onClick={() => window.open('https://github.com/coolerwu', '_blank')}/>
            <Search
              placeholder={'搜索栏'}
              allowClear
              enterButton
              style={{width: '150px'}}
            />
            {
              document.inner.isDevEnv &&
              !getToken() && <Button type={'primary'} onClick={() => listenLoginBtn()}>登录</Button>
            }
          </div>
        )
      }
    </div>
  );
}

export default CustomMenu;