/**
 * 获取token
 */
export function getToken() {
  return localStorage.getItem('token');
}

/**
 * 设置token
 */
export function setToken(token) {
  localStorage.setItem('token', token);
}

/**
 * 清空token
 */
export function clearToken() {
  localStorage.removeItem('token');
}