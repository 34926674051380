import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider} from "react-router-dom";
import {router} from "./config/router";

// 初始化
if (window.location.hostname === 'wulang.vip') {
  document.inner.domain = `${window.location.protocol}//${window.location.hostname}`;
  document.inner.env = `prod`;
  document.inner.isDevEnv = false;
  document.inner.backend = `https://wulang.vip/api`;
} else {
  document.inner.domain = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  document.inner.env = `dev`;
  document.inner.isDevEnv = true;
  // document.inner.backend = `http://localhost:8000`;
  document.inner.backend = `https://wulang.vip/api`;
}
document.inner.filePath = `http://file.wulang.vip/`;

// phone
document.inner.isPC = document.documentElement.clientWidth > 600;
document.inner.isPhone = document.documentElement.clientWidth <= 600;



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);
