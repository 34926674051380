import 'antd/dist/reset.css';
import React, {useEffect, useState} from 'react';
import {ConfigProvider} from "antd";
import {Outlet} from "react-router-dom";
import CustomMenu from "./component/Menu";
import './App.css';
import {accountInfo, collectStatistics} from "./util/request";
import {clearToken} from "./util/auth";

function App() {
  const [totalStatistics, setTotalStatistics] = useState(0);

  useEffect(() => {
    collectStatistics().then(res => {
      if (res?.code === 200 && res?.data) {
        const data = res.data
        setTotalStatistics(data.total);
      }
    });

    accountInfo().then(res => {
      if (!(res?.code === 200 && res?.data)) {
        clearToken();
      }
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
        },
      }}
    >
      <div className={'app-body'}>
        <CustomMenu/>
        <div className={'app-body-sub'}>
          <Outlet/>
          <div className={'footer'}>
            <div className={'footer-desc'}>
              <a href='https://beian.miit.gov.cn' target='_blank' rel="noreferrer">@渝ICP备18008634号</a>
              &nbsp;
              <div>
                {
                  `总访问量: ${totalStatistics}`
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default App;
