import {createBrowserRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
import {Skeleton} from "antd";

import App from "../App";

const BlogModify = lazy(() => import("../page/blog/modify"));
const NotFound = lazy(() => import( "../component/NotFound"));
const Blog = lazy(() => import( "../page/blog"));
const BlogDetail = lazy(() => import( "../page/blog/detail"));
const Stock = lazy(() => import( "../page/stock"));
const Login = lazy(() => import( "../page/login"));
const Food = lazy(() => import( "../page/food"));

export const customMenuArr = [
  {
    path: '/blog',
    label: '个人博客',
    children: [
      {
        path: '/blog',
        element: <Suspense fallback={<Skeleton/>}><Blog type={1}/></Suspense>,
        label: '个人博客',
      },
      {
        path: '/blog/tags',
        element: <Suspense fallback={<Skeleton/>}><Blog type={2}/></Suspense>,
        label: '标签分类',
      },
      {
        path: '/blog/modify',
        element: <Suspense fallback={<Skeleton/>}><BlogModify/></Suspense>,
        label: '文章编辑',
        auth: 'true',
      },
    ],
  },
  {
    path: '/stock',
    label: '金融',
    children: [
      // {
      //   path: '/stock/china',
      //   element: <Suspense fallback={<Skeleton/>}><Stock/></Suspense>,
      //   label: 'A股股票',
      // }
    ],
  },
  {
    path: '/life',
    label: '生活',
    children: [
      {
        path: '/life/food',
        element: <Suspense fallback={<Skeleton/>}><Food/></Suspense>,
        label: '美食',
      },
    ],
  },
  {
    path: '/tool',
    label: '工具',
    children: [
      {
        path: 'https://github.com/coolerwu',
        label: 'Github',
      },
      {
        path: 'https://github.com/coolerwu/FireFire',
        label: '本地笔记本(FireFire)',
      },
    ],
  }
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <Suspense fallback={<Skeleton/>}><NotFound/></Suspense>,
    children: [
      {
        path: '/',
        element: <Suspense fallback={<Skeleton/>}><Blog/></Suspense>,
        label: '个人博客',
      },
      {
        path: '/login',
        element: <Suspense fallback={<Skeleton/>}><Login/></Suspense>,
        label: '登录',
      },
      ...customMenuArr,
      {
        path: '/blog/detail/:id',
        element: <Suspense fallback={<Skeleton/>}><BlogDetail/></Suspense>,
        loader: ({params}) => {
          return {
            id: params.id
          }
        }
      },
    ],
  },
]);