import axios from "axios";
import {message} from "antd";
import {clearToken, getToken} from "./auth";

const prodHost = "https://wulang.vip";

// 创建一个axios
const instance = axios.create({
  baseURL: `${prodHost}/api`,
  timeout: 15000
});

axios.interceptors.request.use(config => {

  config.headers.Authorization = getToken();

  return config;
}, error => {
  return Promise.reject(error);
});

async function request(url, method, body) {
  method = method ?? 'GET';

  const dealResp = res => {
    if (res?.status === 200) {
      const data = res?.data;
      if (data?.code === 200001) {
        clearToken();
        window.location.replace(`${document.inner.domain}/login`);
        return Promise.reject(`用户未登录`);
      }

      return Promise.resolve(res?.data);
    } else {
      message.error(`请求错误: ${res?.status}`);
      return Promise.reject(`请求错误: ${res?.status}`);
    }
  }

  const config = {
    headers: {
      'Authorization': getToken(),
    }
  }

  try {
    let res;
    switch (method.toUpperCase()) {
      case 'POST':
        res = await instance.post(url, body, config);
        break;
      case 'PUT':
        res = await instance.put(url, body, config);
        break;
      case 'DELETE':
        res = await instance.delete(url, config);
        break;
      case 'GET':
      default:
        res = await instance.get(url, config);
    }

    return dealResp(res);
  } catch (e) {
    message.error(`请求错误: ${e}`);
    return Promise.reject(`请求错误: ${e}`);
  }
}

/**
 * 登录
 */
export function login(data) {
  return request(`/account/login`, 'POST', data);
}

/**
 * 账号信息
 */
export function accountInfo() {
  return request(`/account/info`, 'GET');
}

/**
 * 查询文章
 */
export function articleQueryAll(keyword, page, size) {
  if (!keyword) {
    keyword = "";
  }

  return request(`/article/queryAll?keyword=${keyword}&page=${page}&size=${size}`, 'GET');
}

/**
 * 查询文章
 */
export function articleRandomList() {
  return request(`/article/random/list`, 'GET');
}

/**
 * 登录用户查询文章简单列表
 */
export function articleSimpleQueryAll() {
  return request(`/article/simpleQueryAll`, 'GET');
}

/**
 * 查询文章详情
 */
export function articleDetail(id, isEdit = false) {
  return request(`/article/detail?id=${id}&isEdit=${isEdit}`, 'GET');
}


/**
 * 编辑文章详情
 */
export function articleModify(data) {
  return request(`/article/modify`, 'POST', data);
}

/**
 * 查询股票
 */
export function stockQueryAll(tradeDate, name, page, size) {
  return request(`/stock/queryAll?tradeDate=${tradeDate}&name=${name}&page=${page}&size=${size}`, 'GET');
}

/**
 * 查询美食
 */
export function foodQueryAll(keyword, page, size) {
  return request(`/food/queryAll?keyword=${keyword}&page=${page}&size=${size}`, 'GET');
}

/**
 * 编辑美食
 */
export function foodModify(data) {
  return request(`/food/modify`, 'POST', data);
}

/**
 * 统计
 */
export function collectStatistics() {
  return request(`/collect/statisticsAll`, 'GET');
}

/**
 * 轮播图
 */
export function articleCarousel() {
  return request(`/article/carousel`, 'GET');
}

/**
 * 打分
 */
export function articleScoring(articleId, score) {
  return request(`/article/scoring`, 'POST', {
    articleId, score
  });
}

/**
 * 评论列表
 */
export function articleCommentList(articleId, page) {
  return request(`/article/comment/list?articleId=${articleId}&page=${page}`, 'GET');
}

/**
 * 最近评论列表
 */
export function articleCommentRecentList() {
  return request(`/article/comment/recent/list`, 'GET');
}

/**
 * 评论提交
 */
export function articleCommentSubmit(data) {
  return request(`/article/comment/submit`, 'POST', data);
}

/**
 * 文章标签列表
 */
export function articleTagList() {
  return request(`/article/tag/list`, 'GET');
}

/**
 * 获取弹幕
 */
export function danmuRandomList(biz) {
  return request(`/danmu/random/list?biz=${biz}`, 'GET');
}

/**
 * 发送弹幕
 */
export function danmuSend(biz, content) {
  return request(`/danmu/send`, 'POST', {
    biz, content
  });
}